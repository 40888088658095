import React, { useState } from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { Home } from "../components/home";
import {
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  CardContainer,
  CommonCard,
  CardH4,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  VisitLink,
  StoreInput,
  DomainInputTab,
  ImageSectionSegmently,
  ImageInputContainer,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
  ContentLayout,
  CommonColorButton,
  H2Centered,
  PricingTextSection,
} from "../components/common";
import appLogo from "../images/email-before-download-full-logo.svg";
import buttonArrow from "../images/arrow-white.svg";
import carousel1 from "../images/email-before-download-thumbnail-1.png";
import carousel2 from "../images/email-before-download-thumbnail-2.png";
import carousel3 from "../images/email-before-download-thumbnail-3.png";
import imageCampaigns from "../images/campaigns.png";
import imageCustomiseTemplate from "../images/customise-template.png";
import imageDashboard from "../images/dashboard.png";
import imageLeads from "../images/leads.png";
import icon from "../images/icon-seg-1.svg";
import icon2 from "../images/icon-seg-2.svg";
import icon3 from "../images/icon-seg-3.svg";
import icon4 from "../images/icon-seg-4.svg";

const AboutUs = () => {
  const [storeName, setStoreName] = useState("");
  return (
    <>
      <SEO
        title="Team & About Us shopify app"
        description="Free Team & About Us Page Builder for Your Shopify Store"
        noindex
      />
      <Home
        segmently
        appLogo={appLogo}
        logoText="email-before-download-app-logo"
        homeH1={`Free Team & About Us Page Builder for Your Shopify Store`}
        homeText={`Make your About Us page not just a story but also a powerful tool to make your brand stand out from the other Shopify stores while passing on your message. Customers are always eager to know about you. Why not to let them learn about you in a nice way?`}
        imageArray={[
          { src: carousel1, alt: "video thumbline" },
          { src: carousel2, alt: "video thumbline" },
          { src: carousel3, alt: "video thumbline" },
        ]}
        buttonLink="https://apps.shopify.com"
        ButtonText="Add App"
        barColor="#3A6B60"
        alignItems="start"
      />

      <TitleH2Comp
        content={
          <>
            Why {" "}
            <ColoredSpan color="#3A6B60">Team & About Us Page</ColoredSpan> For
            Your Store?
          </>
        }
        barColor="#3A6B60"
      />

      <ContentLayout
        image={
          <img
            src={imageCustomiseTemplate}
            alt="customise template segmently"
            width="100%"
          />
        }
        contentH3={`Endow your shop with a character`}
        contentP1={`Stories have universal meanings. Interpret your experience by using spectacular visuals and a wide range of text layouts.`}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageCampaigns} alt="campaigns segmently" width="100%" />
        }
        contentH3="Show People Behind the Scene"
        contentP1="Showcase your team members in front of your customers with the About Us page. It will not only build trust among your customers but also increase your teams’ morale."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={<img src={imageLeads} alt="leads list segmently" width="100%" />}
        contentH3={`Show Your Customers Various Timelines`}
        contentP1={`Use timeline template to show how you have come to existence and what are your upcoming plans to attract more customers towards your store. Earn customer’s trust and make social proof by sharing your achievements and goals through our timeline template`}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageDashboard} alt="dashboard segmently" width="100%" />
        }
        contentH3="Or just write whatever you want."
        contentP1="We decided to make all the features super flexible so you could get creative. Now, you can add images, videos, texts and have both the timeline and our team on one single page like never before."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      {/* <H2Centered>Pricing</H2Centered>
      <PricingTextSection>
        <p style={{ textAlign: `center` }}>
          Pay as low as <ColoredSpan color="#3A6B60">$4/mo</ColoredSpan> for
          Ask Friends app and start converting indecisive customers to paid
          today. 7 days free trial. Cancel anytime.
        </p>
      </PricingTextSection> */}

      {/* <TitleH2Comp
        content={
          <>
            Pricing
            <ColoredSpan color="#3A6B60"> Boost Your Sales.</ColoredSpan>
          </>
        }
        barColor="#3A6B60"
      />
      <CardContainer segmently>
        <CommonCard segmently>
          <img src={icon} alt="Easy & Powerful" />
          <CardH4>Easy & Powerful</CardH4>
          <CardP>Save your time and money using all-in-one app</CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon2} alt="Lightweight" />
          <CardH4>Lightweight</CardH4>
          <CardP>
            We don’t slow down your website. Segment.ly uses highly scalable
            cloud servers
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon3} alt="No Coding Required" />
          <CardH4>No Coding Required</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon4} alt="20+ Templates" />
          <CardH4>20+ Templates</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
      </CardContainer> */}

      <ImageSectionSegmently>
        <ImageTitleH2>
        Get Team & About Us Page Builder, and Use It As a Powerful Marketing Tool
        </ImageTitleH2>
        <ImageInputContainer>
          <div style={{ display: `flex` }}>
            <StoreInput
              type="text"
              name="store-domain"
              placeholder="Your Store Name"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
            <DomainInputTab>.myshopify.com</DomainInputTab>
          </div>
          <CommonColorButton
            bgColor="#3A6B60"
            hoverBg="#4773ee"
            onClick={() => {
              window.open(`https://apps.shopify.com`);
            }}
          >
            Try It for Free!
          </CommonColorButton>
        </ImageInputContainer>
      </ImageSectionSegmently>

      <TitleH2Comp
        content={
          <>
            Here’s What
            <ColoredSpan color="#3A6B60"> People Are Saying</ColoredSpan>
          </>
        }
        barColor="#3A6B60"
      />
      <ReviewCardContainer>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Using the Facebook like campaign option with a discount code
              really increased our sales. Highly recommended!.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Core Trainer</ReviewCardLowerText>
              <ReviewCardLowerText date>July 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Great App! The best thing in the app is the quick service from
              the support team 🙂 LOVE IT”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Inspiring Wave</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Amazing APP! Interface is simple, heaps of features and great
              straight forward flat monthly fee. Highly Recommended.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Endevz</ReviewCardLowerText>
              <ReviewCardLowerText date>June 10, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “The app did exactly what I needed. It is simple to use and
              creates a nice look on the page. I don’t like that I cannot remove
              the branding at the bottom, but that is minor. I did have a
              problem with the app and…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Blue Flamingo Studio</ReviewCardLowerText>
              <ReviewCardLowerText date>March 8, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>“Great app. Olaf is always available for questions.”</CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>
                Bare Skin By Dr Bollmann
              </ReviewCardLowerText>
              <ReviewCardLowerText date>May 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “VERY basic. It doesn’t allow to customize the page, it’s very
              generic. It’s a cool concept, so I hope the app is further
              developed and have better features…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Kallie Shoes</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
      </ReviewCardContainer>
      <ImageSection>
        <ImageTitleH2>
          We Touched The Life Of{" "}
          <ColoredSpan color="#2D2D51"> 600+</ColoredSpan> Merchants’ Custom
          Development
        </ImageTitleH2>
        <VisitLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.shopify.com/partners/appscom-io"
        >
          <CommonArrowButton>
            <div>Browse Apps</div>
            <ButtonArrow>
              <img
                src={buttonArrow}
                alt="arrow"
                style={{ marginLeft: `10px` }}
              />
            </ButtonArrow>
          </CommonArrowButton>
        </VisitLink>
      </ImageSection>
    </>
  );
};

export default AboutUs;
